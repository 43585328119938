import { Fragment, useState, useRef  } from 'react'
import { Dialog, RadioGroup, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { StarIcon } from '@heroicons/react/20/solid'
import {processAxiosError, showError, showSuccess} from "../utils/helper";


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const ProductModal = (props) => {


  const [open, setOpen] = useState(true)
  const {showProductModal, setShowProductModal, addToCart, addToCartReturn, product, setProduct, mode} = props;
  const checkboxReductionPrice = useRef();
  const [processing, setProcessing] = useState(false)
  const priceRef = useRef("");

    const addProductToWarehouse = async () => {
        setProcessing(true)
        // Store to database
        await window.axios.post('/stocks', {
            product: product
        })
        .then((response) => {
            setProcessing(false)

            console.log(response)
            if(response.status === 201 && response.data.success === true) {
                console.log(response.data.data)
                showSuccess('Proizvod je uspješno dodan na skladište!')
                setProduct(response.data.data)
                //setShowReceiptModal(false)
            } else if(response.status === 200 && response.data.success === false) {
                console.log(response.data)
                showError(response.data.error)

            } else{
                showError('Došlo je do greške! Error: ' + response.status)
            }
        })
        .catch((error) => {
            setProcessing(false)
            processAxiosError(error)
        })
    }

  return (
    <Transition.Root show={showProductModal} as={Fragment}>

      <Dialog as="div" className="relative z-10" onClose={setShowProductModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="glass opacity-100 flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                <div className="relative flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8 rounded-lg">
                  <button
                    type="button"
                    className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
                    onClick={() => setShowProductModal(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  <div className="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-12 lg:gap-x-8">
                    <div className="aspect-h-3 aspect-w-2 overflow-hidden rounded-lg bg-gray-100 sm:col-span-4 lg:col-span-5">
                      <img src={product.picture_url} alt={product.name} className="object-cover object-center" />
                    </div>
                      <span className={(product.sell_without_stock === 1
    ? "text-white bg-blue-500"
    : product.stock_quantity > 0
    ? "text-black bg-green-400"
    : "text-white bg-red-500") + " absolute text-xs font-semibold inline-block py-1 px-3 uppercase rounded-tl-md  rounded-br-md uppercase last:mr-0 mr-1"}>
                        {product.stock_quantity}
                      </span>
                    <div className="sm:col-span-8 lg:col-span-7">
                        <p className="text-xl text-blue-500 mb-3">
                          {product.code}
                        </p>
                      <h2 className="text-2xl font-bold text-gray-900 sm:pr-12">{product.name}</h2>

                      <section aria-labelledby="information-heading" className="mt-2">


                        {mode !== "return" && product.reduction && <div className="relative flex items-center gap-x-3">
                                  <div className="text-sm leading-6">
                                    <label htmlFor="comments" className="text-xl text-red-500">
                                      {product.reduction_price_with_tax_f} {product.currency}
                                    </label>
                                  </div>
                                  <div className="flex h-6 items-center">
                                    <input
                                      id="recuction-price"
                                      name="recuctionPrice"
                                      ref={checkboxReductionPrice}
                                      type="checkbox"
                                      className="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-600"
                                    />
                                  </div>
                                </div>}
                        <p className="text-2xl text-gray-900">
                          {product.price_with_tax_f} {product.currency}
                        </p>
                        {mode === "return" &&
                        <p className="text-2xl text-gray-900">
                          <input
                          id="product-price"
                          name="productPrice"
                          ref={priceRef}
                          defaultValue={product.price_with_tax}
                          type="text"
                          className="relative block flex-auto rounded border border-solid border-neutral-300 bg-transparent  px-3 py-[0.25rem] font-normal  text-black outline-none focus:text-black  "
                          />
                          </p>
                        }
                        <div className="mt-6">
                          <div className="flex items-center">
                            <a href="#" className="text-sm font-medium text-black hover:text-indigo-500">
                              {product.description}
                            </a>
                          </div>
                        </div>
                      </section>

                      <section aria-labelledby="options-heading" className="mt-10">

{mode === "return" ?
                          <button
                            type="submit"
                            onClick={() => {
                              addToCartReturn(product, priceRef.current.value)
                              setShowProductModal(false)
                            }}
                            className={"mt-6 flex w-full items-center justify-center rounded-md border border-transparent px-8 py-3 font-bold text-black  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 bg-red-500 hover:bg-red-700 text-black hover:text-white"}
                          >
                            Povrat proizvoda
                          </button> : <button
                            type="submit"
                            disabled={product.stock_quantity <= 0 && product.sell_without_stock === 0}
                            onClick={() => {
                              addToCart(product, (checkboxReductionPrice.current ? checkboxReductionPrice.current.checked : false))
                              setShowProductModal(false)
                            }}
                            className={"mt-6 flex w-full items-center justify-center rounded-md border border-transparent px-8 py-3 font-bold text-black  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 " +(product.stock_quantity > 0  || product.sell_without_stock === 1 ? "bg-primary hover:bg-red-500 text-black" : "bg-gray-500 text-white")}
                          >
                            Dodaj u košaricu
                          </button>}
                        {mode === "sale" && product.stock_quantity <= 0 && product.sell_without_stock === 0 &&
                           <button
                            type="submit"
                            disabled={processing}
                            onClick={() => {
                              console.log("warehouse add")
                              addProductToWarehouse()
                            }}
                            className={"mt-6 flex w-full items-center justify-center rounded-md border border-transparent px-8 py-3 font-bold text-black  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 bg-primary hover:bg-red-500 text-black" }
                          >
                            Dodaj proizvod na skladište
                          </button>}
                      </section>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
export default ProductModal
